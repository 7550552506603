import "../../../../../../frappe/frappe/public/js/frappe/form/formatters";

// method with same content as frappe original method, but with overrides to allow passing custom name in options and custom href in options, and also translating Child Doc titles
frappe.form.formatters.Link = function (value, docfield, options, doc) {
  var doctype = docfield._options || docfield.options;
  // Nexfront: Override to allow passing custom name in options
  var original_value = options?.name ?? value;
  let link_title = frappe.utils.get_link_title(doctype, value);

  if (link_title === value) {
    link_title = null;
  }

  if (value && value.match && value.match(/^['"].*['"]$/)) {
    value.replace(/^.(.*).$/, "$1");
  }

  if (options && (options.for_print || options.only_value)) {
    return link_title || value;
  }

  if (frappe.form.link_formatters[doctype]) {
    // don't apply formatters in case of composite (parent field of same type)
    if (doc && doctype !== doc.doctype) {
      value = frappe.form.link_formatters[doctype](value, doc, docfield);
    }
  }

  if (!value) {
    return "";
  }
  if (value[0] == "'" && value[value.length - 1] == "'") {
    return value.substring(1, value.length - 1);
  }
  if (docfield && docfield.link_onclick) {
    return repl('<a onclick="%(onclick)s" href="#">%(value)s</a>', {
      onclick:
        docfield.link_onclick.replace(/"/g, "&quot;") + "; return false;",
      value: value,
    });
  } else if (docfield && doctype) {
    // Nexfront: Override to allow translating Child Doc titles
    // if (docfield.can_read ?? frappe.model.can_read(doctype)) {
    if (
      frappe.model.is_table(doctype) ||
      (docfield.can_read ?? frappe.model.can_read(doctype))
    ) {
      const a = document.createElement("a");
      // Nexfront: Override to add pointer-events: none to link if is a table
      if (frappe.model.is_table(doctype)) a.style.pointerEvents = "none";
      // Nexfront: Override to allow passing custom href in options and to not add href if is a table
      a.href = frappe.model.is_table(doctype)
        ? "#"
        : options?.href ??
          `/app/${encodeURIComponent(
            frappe.router.slug(doctype),
          )}/${encodeURIComponent(original_value)}`;
      a.dataset.doctype = doctype;
      a.dataset.name = original_value;
      a.dataset.value = original_value;
      a.innerText = __((options && options.label) || link_title || value);
      return a.outerHTML;
    } else {
      return link_title || value;
    }
  } else {
    return link_title || value;
  }
};

// method with same content as frappe original method, but with overrides to allow passing custom href in options and format status pill if passing options.color
frappe.form.formatters.Data = function (value, df, options) {
  if (df && df.options == "URL") {
    if (!value) return;
    // Nexfront: Override to allow passing custom href in options
    const href = options?.href ?? value;
    return `<a href="${href}" title="Open Link" target="_blank">${value}</a>`;
  }
  // Nexfront: Override to format status pill if passing options.color
  else if (df && df.options == "Status" && options.color) {
    if (!value) return;
    return `<span class="indicator-pill no-indicator-dot whitespace-nowrap ${options.color}"><span>${value}</span></span>`;
  }
  value = value == null ? "" : value;

  return frappe.form.formatters._apply_custom_formatter(value, df);
};

// method with same content as frappe original method, but with left alignment instead of right alignment
frappe.form.formatters.Float = function (value, docfield, options, doc) {
  if (value === null) {
    return "";
  }

  // don't allow 0 precision for Floats, hence or'ing with null
  var precision =
    docfield.precision ||
    cint(frappe.boot.sysdefaults && frappe.boot.sysdefaults.float_precision) ||
    null;
  if (docfield.options && docfield.options.trim()) {
    // options points to a currency field, but expects precision of float!
    docfield.precision = precision;
    return frappe.form.formatters.Currency(value, docfield, options, doc);
  } else {
    // show 1.000000 as 1
    if (!(options || {}).always_show_decimals && !is_null(value)) {
      var temp = cstr(value).split(".");
      if (temp[1] == undefined || cint(temp[1]) === 0) {
        precision = 0;
      }
    }

    value = value == null || value === "" ? "" : value;

    // Nexfront: Override to align text to the left instead of right
    // return frappe.form.formatters._right(format_number(value, null, precision), options);
    return format_number(value, null, precision);
  }
};

// method with same content as frappe original method, but with left alignment instead of right alignment
frappe.form.formatters.Int = function (value, docfield, _options) {
  if (value === null) {
    return "";
  }

  if (cstr(docfield.options).trim() === "File Size") {
    return frappe.form.formatters.FileSize(value);
  }
  // Nexfront: Override to align text to the left instead of right
  // return frappe.form.formatters._right(value == null ? "" : cint(value), options);
  return value == null ? "" : cint(value);
};

// method with same content as frappe original method, but with left alignment instead of right alignment
frappe.form.formatters.Percent = function (value, docfield, _options) {
  if (value === null) {
    return "";
  }

  const precision =
    docfield.precision ||
    cint(frappe.boot.sysdefaults && frappe.boot.sysdefaults.float_precision) ||
    2;
  // Nexfront: Override to align text to the left instead of right
  // return frappe.form.formatters._right(flt(value, precision) + "%", options);
  return flt(value, precision) + "%";
};

// method with same content as frappe original method, but with left alignment instead of right alignment
frappe.form.formatters.Currency = function (value, docfield, options, doc) {
  if (value === null) {
    return "";
  }

  var currency = frappe.meta.get_field_currency(docfield, doc);

  let precision;
  if (typeof docfield.precision == "number") {
    precision = docfield.precision;
  } else {
    precision = cint(
      docfield.precision || frappe.boot.sysdefaults.currency_precision || 2,
    );
  }

  // If you change anything below, it's going to hurt a company in UAE, a bit.
  if (precision > 2) {
    var parts = cstr(value).split("."); // should be minimum 2, comes from the DB
    var decimals = parts.length > 1 ? parts[1] : ""; // parts.length == 2 ???

    if (decimals.length < 3 || decimals.length < precision) {
      const fraction =
        frappe.model.get_value(":Currency", currency, "fraction_units") || 100; // if not set, minimum 2.

      if (decimals.length < cstr(fraction).length) {
        precision = cstr(fraction).length - 1;
      }
    }
  }

  value = value == null || value === "" ? "" : value;
  value = format_currency(value, currency, precision);

  if (options && options.only_value) {
    return value;
  } else {
    // Nexfront: Override to align text to the left instead of right
    // return frappe.form.formatters._right(value, options);
    return value;
  }
};

// method with same content as frappe original method, but with override to first check if doc?.doctype is "User"
frappe.form.link_formatters["User"] = function (value, doc, docfield) {
  // Nexfront: Override to first check if doc?.doctype is "User" before checking for full_name
  let full_name =
    doc?.doctype === "User" &&
    (doc.full_name || (docfield && doc[`${docfield.fieldname}_full_name`]));
  return full_name || value;
};
